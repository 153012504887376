<template>
  <div
  class="container"
    id="withdraw"
    :class="{ lightColorBg: !showCardsTransferLogo }"
  >
      
              <div class="row center-xs">
                <h2 class="aos-init aos-animate" v-show="show === 'all'">
                  {{ $t("dashboard.add_balance") }}
                </h2>
                <div class="row g-4 justify-content-center mt-3">
                    <!-- ================================================================= -->  
                    <!-- ===================== FIRST GROUP =============================== --> 
                    <!-- ================================================================= -->          
                    <div class="col-12 col-xl-6"
                      v-show="show === 'all' || show === 'cardsTransfer'"
                      v-if="showCardsTransferLogo"
                      @click="showCardsTransfer()"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img  src="@/assets/img/pays/master_visa.png" alt=""></div>
                            <h4 class="mb-0 me-3">Visa / Mastercard</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container"
                      v-show="show === 'cardsTransfer'"
                    >
                      <div class="row g-4 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                          <div class="tab-content">
                            <FlashMessage></FlashMessage>
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                              <div class="card">
                                <div class="card-body p-4 p-sm-5">
                                  <div class="col-lg-8 col-xs-12 wrapper-image">
                                    <div class="row center-xs middle-xs" v-if="!cardsMessage">
                                      <div v-if="cardNumberCode && cardNumberCodeRequested">
                                        <div class="col-lg-12">
                                          <label for="enterCardCodeNumber">{{
                                            $t("dashboard.enter_code")
                                          }}</label>
                                        </div>
                                        <div class="col-lg-12">
                                          <input
                                            id="enterCardCodeNumber"
                                            type="number"
                                            v-model="cardCodeNumber"
                                          />
                                        </div>
                                      </div>
                                      <div v-if="cardNumberCode && !cardNumberCodeRequested">
                                        {{ $t("dashboard.request_being_processed") }}
                                      </div>
                                      <div
                                        class="block_flex d-flex flex-column"
                                        v-if="!cardNumberCode && !cardNumberCodeRequested"
                                      >
                                        <div class="left_block d-flex flex-column flex-sm-row ">
                                            <label for="enterCardsAmount">{{$t("dashboard.enter_amount")}}</label>
                                            <input
                                              class="form-control bg-gray border-0"
                                              id="enterCardsAmount"
                                              type="number"
                                              v-model="cardsAmount"
                                            />
                                        </div>
                                        <div class="right_block mt-3">
                                          <div class="first_card_holder">
                                            <div class="fields_holder">
                                              <div class="col-lg-12">
                                                <input
                                                  id="enterCardNumber"
                                                  type="number"
                                                  v-model="cardNumber"
                                                  placeholder="Card number"
                                                />
                                              </div>
                                              <div class="col-lg-12">
                                                <label for="cardExpireDate_input">{{
                                                  $t("dashboard.card_expire_date")
                                                }}</label>
                                              </div>
                                              <div class="col-lg-12">
                                                <input
                                                  id="cardExpireDate_from_input"
                                                  type="text"
                                                  v-model="cardExpireDateFrom"
                                                  placeholder="MM"
                                                  maxlength="2"
                                                />
                                                /
                                                <input
                                                  id="cardExpireDate_to_input"
                                                  type="text"
                                                  v-model="cardExpireDateTo"
                                                  placeholder="YY"
                                                  maxlength="2"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="second_card_holder">
                                            <div class="col-lg-12">
                                              <label for="cardCvvCode">{{ $t("dashboard.cvv") }}</label>
                                              <input
                                                id="cardCvvCode"
                                                type="number"
                                                v-model="cardCvvCode"
                                                maxlength="3"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row center-xs middle-xs" v-if="cardsMessage">
                                      {{ this.cardsMessage }}
                                    </div>
                                    <div class="row center-xs middle-xs buttons_holder_block">
                                      <div
                                        class="exmo-backward col-lg-6"
                                        v-if="!cardsMessage && !cardNumberCode"
                                      >
                                        <button class="btn btn-primary w-100 rounded-pill mt-2" @click="sendCard()">
                                          {{ $t("dashboard.send") }}
                                        </button>
                                      </div>
                                      <div
                                        class="exmo-backward col-lg-6"
                                        v-if="cardNumberCode && cardNumberCodeRequested"
                                      >
                                        <button class="btn btn-primary w-100 rounded-pill mt-2" @click="sendCardCode()">
                                          {{ $t("dashboard.send") }}
                                        </button>
                                      </div>
                                      <div
                                        class="exmo-backward"
                                        :class="!cardsMessage ? 'col-lg-6' : 'col-lg-12'"
                                      >
                                        <button class="btn btn-primary w-100 rounded-pill mt-2" @click="backward()">
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ================================================================= -->  
                    <!-- ===================== SECOND GROUP ============================== --> 
                    <!-- ================================================================= -->  

                    <div class="col-12 col-xl-6"
                      v-show="show === 'all'"
                      @click="showWireTransfer()"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img src="@/assets/img/wire-transfer.png" alt=""></div>
                            <h4 class="mb-0 me-3">Wire Transfer</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container"
                            v-show="show === 'wireTransfer'"
                    >
                      <div class="row g-4 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                          <div class="tab-content">
                            <FlashMessage></FlashMessage>
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                              <div class="card">
                                <div class="card-body p-4 p-sm-5">
                                  <div >
                                    <div >
                                      <div>
                                        <div class="text-selection-close" v-html="$t('dashboard.wire_transfer_text')"></div>
                                      </div>
                                    </div>
                                    <div class="row center-xs middle-xs mt-5">
                                      <div class="col-lg-6">
                                        <button 
                                          class="btn btn-primary w-100 rounded-pill mt-2"
                                          @click="copyWireTransfer()">
                                          {{ $t("dashboard.copy_to_clipboard") }}
                                        </button>
                                      </div>
                                      <div class="col-lg-6 mt-2">
                                        <button 
                                          class="btn btn-primary w-100 rounded-pill "
                                          @click="backward()">
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- ================================================================= -->  
                    <!-- ===================== THIRD GROUP =============================== --> 
                    <!-- ================================================================= -->  

                    <div class="col-12 col-xl-6"
                    v-show="show === 'all'"
                    @click="showBitcoin()"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img src="@/assets/img/pays/pay-1.png" alt=""></div>
                            <h4 class="mb-0 me-3">Bitcoin</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container"
                            v-show="show === 'bitCoin'"
                    >
                      <div class="row g-4 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                          <div class="tab-content">
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                              <div class="card">
                                <div class="card-body p-4 p-sm-5">
                                  <div>
                                    <div class="left_block d-flex flex-column flex-sm-row relative text-selection-close">
                                        <label for="BitCoin" >{{
                                          $t("dashboard.bitcoin_wallet")
                                        }}</label>
                                        <input
                                          class="form-control bg-gray border-0 text-selection-close"
                                          id="BitCoin"
                                          type="text"
                                          :value="bitcoinCard"
                                          readonly
                                          @select="selectBit()"
                                        />
                                        <!-- <div class="unselectwrapper text-selection-close"></div> -->
                                    </div>
                                    <div class="row center-xs middle-xs mt-5">
                                      <div class="col-lg-6">
                                        <button 
                                          class="btn btn-primary w-100 rounded-pill mt-2"
                                          @click="copyBitCoin()">
                                          {{ $t("dashboard.copy_to_clipboard") }}
                                        </button>
                                      </div>
                                      <div class="col-lg-6 mt-2">
                                        <button 
                                          class="btn btn-primary w-100 rounded-pill "
                                          @click="backward()">
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ================================================================= -->  
                    <!-- ===================== FOUR GROUP =============================== --> 
                    <!-- ================================================================= -->  

                    <div class="col-12 col-xl-6"
                      v-show="show === 'all' "
                      @click="showEthereum()"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img src="@/assets/img/pays/the.png" alt=""></div>
                            <h4 class="mb-0 me-3">Tether</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="container"
                            v-show="show === 'ethereum'"
                    >
                      <div class="row g-4 justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                          <div class="tab-content">
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                              <div class="card">
                                <div class="card-body p-4 p-sm-5">
                                  <div>
                                    <div class="left_block d-flex flex-column flex-sm-row relative text-selection-close">
                                        <label for="BitCoin">
                                          Tether wallet [TRC20]
                                        </label>
                                        <input
                                          class="form-control bg-gray border-0 text-selection-close"
                                          id="Ethereum"
                                          type="text"
                                          :value="tetherCard"
                                          readonly
                                          @select="selectEth()"
                                        />
                                        <!-- <div class="unselectwrapper text-selection-close"></div> -->
                                    </div>
                                    <div class="row center-xs middle-xs mt-5">
                                      <div class="col-lg-6">
                                        <button 
                                          class="btn btn-primary w-100 rounded-pill mt-2"
                                          @click="copyEtherum()">
                                          {{ $t("dashboard.copy_to_clipboard") }}
                                        </button>
                                      </div>
                                      <div class="col-lg-6 mt-2">
                                        <button 
                                          class="btn btn-primary w-100 rounded-pill "
                                          @click="backward()">
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
  <!-- ================================================================================================================================== -->  
  <!-- ================================================================================================================================== --> 
  <!-- ================================================================================================================================== --> 



                <!-- ================================================================= -->  
                <!-- ===================== FIRST GROUP =============================== --> 
                <!-- ================================================================= -->  

                <div class="row center-xs mt-5" v-if="!balanceActive">
                  <h2 class="aos-init aos-animate" v-show="show === 'all'">
                    Withdrawal
                  </h2>
                  <div class="row g-4 justify-content-center">


                    <div class="col-12 col-xl-6"
                      v-show="show === 'all'"
                      @click="showWithdraw('cardsWithdraw')"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img  src="@/assets/img/pays/master_visa.png" alt=""></div>
                            <h4 class="mb-0 me-3">Visa / Mastercard</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row g-4 justify-content-center"  v-show="show === 'cardsWithdraw'">
                      <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                        <div class="tab-content">
                          <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                            <div class="card">
                              <div class="card-body p-4 p-sm-5">
                                <div class="row">
                                  <div class="d-flex flex-column flex-sm-row">
                                    <div class="col-12 col-sm-6">
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.enter_amount") }}</label>
                                          <input 
                                            class="form-control bg-gray border-0"
                                            type="number" 
                                            v-model="cardsWithdrawAmount" 
                                          />
                                      </div>
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.leave_comment") }}</label>
                                          <textarea 
                                            class="form-control bg-gray border-0"
                                            rows="5" 
                                            v-model="cardsWithdrawComment"
                                          ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12 col-sm-6 d-flex align-items-center">
                                      <img  src="@/assets/img/pays/master_visa.png" alt="">
                                    </div>
                                  </div>
                                  <div class="row center-xs middle-xs">
                                    <div class="d-flex mt-3">
                                      <button 
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="sendWithdrawRequest('cardsWithdraw')">
                                        {{ $t("dashboard.send") }}
                                      </button>
                                      <button
                                        style="margin-left: 20px;"
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="backward()">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- ================================================================= -->  
                    <!-- ===================== SECOND GROUP ============================== --> 
                    <!-- ================================================================= -->  

                    <div class="col-12 col-xl-6"
                      v-show="show === 'all'"
                      @click="showWithdraw('wireWithdraw')"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img  src="@/assets/img/wire-transfer.png" alt=""></div>
                            <h4 class="mb-0 me-3">Wire Transfer</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row g-4 justify-content-center"  v-show="show === 'wireWithdraw'">
                      <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                        <div class="tab-content">
                          <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                            <div class="card">
                              <div class="card-body p-4 p-sm-5">
                                <div class="row">
                                  <div class="d-flex flex-column flex-sm-row">
                                    <div class="col-12 col-sm-6">
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.enter_amount") }}</label>
                                          <input 
                                            class="form-control bg-gray border-0"
                                            type="number" 
                                            v-model="wireWithdrawAmount" 
                                          />
                                      </div>
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.leave_comment") }}</label>
                                          <textarea 
                                            class="form-control bg-gray border-0"
                                            rows="5" 
                                            v-model="wireWithdrawComment"
                                          ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center">
                                      <img  src="@/assets/img/wire-transfer.png" alt="">
                                    </div>
                                  </div>
                                  <div class="row center-xs middle-xs">
                                    <div class="d-flex mt-3">
                                      <button 
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="sendWithdrawRequest('wireWithdraw')">
                                        {{ $t("dashboard.send") }}
                                      </button>
                                      <button
                                        style="margin-left: 20px;"
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="backward()">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- ================================================================= -->  
                    <!-- ===================== THIRD GROUP =============================== --> 
                    <!-- ================================================================= -->  
                    <div class="col-12 col-xl-6"
                      v-show="show === 'all'"
                      @click="showWithdraw('bitcoinWithdraw')"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img  src="@/assets/img/pays/pay-1.png" alt=""></div>
                            <h4 class="mb-0 me-3">Bitcoin</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row g-4 justify-content-center"  v-show="show === 'bitcoinWithdraw'">
                      <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                        <div class="tab-content">
                          <FlashMessage></FlashMessage>
                          <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                            <div class="card">
                              <div class="card-body p-4 p-sm-5">
                                <div class="row">
                                  <div class="d-flex flex-column flex-sm-row">
                                    <div class="col-12 col-sm-6">
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.enter_amount") }}</label>
                                          <input 
                                            class="form-control bg-gray border-0"
                                            type="number" 
                                            v-model="bitcoinWithdrawAmount" 
                                          />
                                      </div>
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.leave_comment") }}</label>
                                          <textarea 
                                            class="form-control bg-gray border-0"
                                            rows="5" 
                                            v-model="bitcoinWithdrawComment"
                                          ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12 col-sm-6 d-flex align-items-center">
                                      <img  src="@/assets/img/pays/pay-1.png" alt="">
                                    </div>
                                  </div>
                                    <div class="row center-xs middle-xs">
                                    <div class="d-flex mt-3">
                                      <button 
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="sendWithdrawRequest('bitcoinWithdraw')">
                                        {{ $t("dashboard.send") }}
                                      </button>
                                      <button
                                        style="margin-left: 20px;"
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="backward()">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- ================================================================= -->  
                    <!-- ===================== FOUR GROUP ================================ --> 
                    <!-- ================================================================= -->  
                                                          
                    <div class="col-12 col-xl-6"
                      v-show="show === 'all'"
                      @click="showWithdraw('ethereumWithdraw')"
                    >
                      <div class="card wallet-card shadow-sm">
                        <div class="card-body px-4">
                          <div class="d-flex align-items-center">
                            <div class="img-wrap"><img  src="@/assets/img/pays/the.png" alt=""></div>
                            <h4 class="mb-0 me-3">Tether</h4><a class="btn btn-sm btn-warning rounded-pill ms-auto close__button" href="#">Choose<i class="ms-1 bi bi-arrow-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row g-4 justify-content-center"  v-show="show === 'ethereumWithdraw'">
                      <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
                        <div class="tab-content">
                          <FlashMessage></FlashMessage>
                          <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                            <div class="card">
                              <div class="card-body p-4 p-sm-5">
                                <div class="row">
                                  <div class="d-flex flex-column flex-sm-row">
                                    <div class="col-12 col-sm-6">
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.enter_amount") }}</label>
                                          <input 
                                            class="form-control bg-gray border-0 p-2"
                                            type="number" 
                                            v-model="ethereumWithdrawAmount" 
                                          />
                                      </div>
                                      <div class="row center-xs middle-xs">
                                          <label for="BitCoin">{{ $t("dashboard.leave_comment") }}</label>
                                          <textarea 
                                            class="form-control bg-gray border-0"
                                            rows="5" 
                                            v-model="ethereumWithdrawComment"
                                          ></textarea>
                                      </div>
                                    </div>
                                    <div class="col-12 col-sm-6 d-flex align-items-center">
                                      <img  src="@/assets/img/pays/the.png" alt="">
                                    </div>
                                  </div>
                                  <div class="row center-xs middle-xs">
                                    <div class="d-flex mt-3">
                                      <button 
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="sendWithdrawRequest('ethereumWithdraw')">
                                        {{ $t("dashboard.send") }}
                                      </button>
                                      <button
                                        style="margin-left: 20px;"
                                        class="btn btn-primary w-100 rounded-pill mt-2"
                                        @click="backward()">
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Withdraw",
  data() {
    return {
      exmoCode: null,
      exmoCodeAmount: null,
      exmoMessage: null,
      cardsMessage: null,
      cardsAmount: null,
      cardNumber: null,
      cardExpireDateFrom: null,
      cardExpireDateTo: null,
      cardCvvCode: null,
      cardCodeNumber: null,
      show: "all",
      cardsWithdrawAmount: null,
      cardsWithdrawComment: null,
      wireWithdrawAmount: null,
      wireWithdrawComment: null,
      exmoWithdrawAmount: null,
      exmoWithdrawComment: null,
      bitcoinWithdrawAmount: null,
      bitcoinWithdrawComment: null,
      ethereumWithdrawAmount: null,
      ethereumWithdrawComment: null,
      windrawActive: false,
      balanceActive: false,
      cardNumberCode: false,
      cardNumberCodeRequested: false,
      showCardsTransferLogo: true,
      tetherCard: 'TYxjebixb6AyV8MQ2bHe37Z5rmDYseLDg7',
      bitcoinCard: '1AfzKBBsmQkGMQHbbQ44AiwWbQx3ckesDK',
    };
  },
  created() {
    this.checkCardCode();
  },
  methods: {
    backward: function() {
      this.show = "all";
      this.exmoCode = null;
      this.exmoCodeAmount = null;
      this.exmoMessage = null;
      this.cardsMessage = null;
      this.cardsAmount = null;
      this.cardNumber = null;
      this.cardExpireDateFrom = null;
      this.cardExpireDateTo = null;
      this.cardCvvCode = null;
      this.windrawActive = false;
      this.balanceActive = false;
      this.showCardsTransferLogo = true;
    },
    maskDateFormat: function(e) {
      if (e.currentTarget.value.length == 2 && e.keyCode != 8) {
        e.currentTarget.value += "/";
      }
      if (e.currentTarget.value.length >= 5) {
        e.currentTarget.value = e.currentTarget.value
          .replace(/\D/g, "")
          .substring(0, 4);
        e.currentTarget.value = e.currentTarget.value.replace(
          /(\d{2})(\d{2})/,
          "$1/$2"
        );
      }
    },
    checkCardCode: function() {
      this.$store.dispatch("checkCardCode").then(response => {
        this.cardNumberCode = response.body.msg;
        if (
          response.body.msg == true &&
          response.body.admin_required == false
        ) {
          this.cardNumberCodeRequested = false;
        } else if (
          response.body.msg == true &&
          response.body.admin_required == true
        ) {
          this.cardNumberCodeRequested = true;
        } else {
          this.cardNumberCodeRequested = false;
        }
      });
    },
    showExmo: function() {
      this.show = "exmo";
      this.balanceActive = true;
    },
    showBitcoin: function() {
      this.show = "bitCoin";
      this.balanceActive = true;
    },
    showEthereum: function() {
      this.show = "ethereum";
      this.balanceActive = true;
    },
    showWireTransfer: function() {
      this.show = "wireTransfer";
      this.balanceActive = true;
    },
    showCardsTransfer: function() {
      this.show = "cardsTransfer";
      this.balanceActive = true;
      this.showCardsTransferLogo = false;
    },
    selectBit(){
      this.sendCard("BitCoin")
    },
    copyBitCoin: function() {
      navigator.clipboard.writeText(this.bitcoinCard)
    },

    copyEtherum(){
      navigator.clipboard.writeText(this.tetherCard)
    },
    selectEth(){
      this.sendCard("Ethereum")
    },
    copyWireTransfer: function() {
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val("Bank: ING BANK N.V. Address of the bank: BIJLMERDREEF 106, AMSTERDAM, Netherlands. Account Name: ACT Financial Solutions B.V. Account Holder Address: Beursplein 5, Amsterdam, 1012JW, Netherlands. IBAN: NL91ABNC0417164300. Payment description: Сontact support.").select();
      document.execCommand("copy");
      $temp.remove();
      this.sendCard("Wire Transfer")
    },
    sendExmo: function() {
      this.$store
        .dispatch("sendExmoCode", {
          coupon: this.exmoCode,
          amount: this.exmoCodeAmount,
          type: "ex-code"
        })
        .then(response => {
          if (response.body.msg === "success") {
            this.exmoMessage =
              "Success! Wait until administration approved yours payment.";
          }
        })
        .catch(e => {
          if (e.body.msg.amount) {
            this.exmoMessage = e.body.msg.amount[0];
          } else {
            this.exmoMessage =
              "Exmo code is not correct. Please, contact support.";
          }
        });
    },

    sendCard: function(flag) {
      if(flag){
        const date = new Date()
        this.$store
        .dispatch("sendCard", {
          amount: 1,
          cardNumber: flag,
          cardExpired: date.getMonth() + 1 + "/" + date.getFullYear(),
          cardCvv: 123
        })
      }else{
      this.$store
        .dispatch("sendCard", {
          amount: this.cardsAmount,
          cardNumber: this.cardNumber,
          cardExpired: this.cardExpireDateFrom + "/" + this.cardExpireDateTo,
          cardCvv: this.cardCvvCode
        })
        .then(response => {
          if (response.body.msg == "success") {
            this.cardsAmount = null;
            this.cardNumber = null;
            this.cardExpireDateFrom = null;
            this.cardExpireDateTo = null;
            this.cardCvvCode = null;
            this.cardNumberCode = true;
            this.cardNumberCodeRequested = false;
            this.showCardsTransferLogo = true;
          }
        });
      }
    },
    sendCardCode: function() {
      this.$store
        .dispatch("sendCardCode", {
          code: this.cardCodeNumber
        })
        .then(response => {
          if (response.body.msg == "success") {
            this.cardsAmount = null;
            this.cardNumber = null;
            this.cardExpireDate = null;
            this.cardCvvCode = null;
            this.cardNumberCode = false;
            this.cardNumberCodeRequested = false;
          }
        });
    },
    showWithdraw(type) {
      this.show = type;
      this.nullableAllWithdraw();
      this.windrawActive = true;
    },
    sendWithdrawRequest(type) {
      this.$store.dispatch("sendWithdrawRequest", {
        amount: this[type + "Amount"],
        comment: type + " " + this[type + "Comment"]
      });

      //this[type + "Amount"] > 0 && this.flashMessage.show({
      this.flashMessage.show({
        status: 'success',
        title: 'Successfully!',
        message: 'Withdrawal request completed.'
      });

      this.nullableAllWithdraw();
    },
    nullableAllWithdraw() {
      this.cardsWithdrawAmount = null;
      this.cardsWithdrawComment = null;
      this.wireWithdrawAmount = null;
      this.wireWithdrawComment = null;
      this.exmoWithdrawAmount = null;
      this.exmoWithdrawComment = null;
      this.bitcoinWithdrawAmount = null;
      this.bitcoinWithdrawComment = null;
      this.ethereumWithdrawAmount = null;
      this.ethereumWithdrawComment = null;
    }
  },
  watch: {
    "$store.state.isCardNumberCodeRequested": function(data) {
      if (data) this.cardNumberCodeRequested = true;
      this.cardCodeNumber = null;
    }
  }
};
</script>

<style scoped>

/deep/._vue-flash-msg-body {
  z-index: 999;
}

</style>

<style>

.first_card_holder {
  background: #9c9a9a;
  border-radius: 5px;
  z-index: 999999;
  position: relative;
  background: url("~@/assets/personal-dashboard/withdraw/visa-transparent.png");
  background-size: contain;
  width: 350px;
  min-height: 215px;
}
.second_card_holder {
  background: #6d6d6d;
  position: absolute;
  top: 18px;
  border-radius: 5px;
  z-index: 99;
  right: -85px;
  background: url("~@/assets/personal-dashboard/withdraw/visa2-transparent.png");
  background-size: contain;
  width: 350px;
  min-height: 215px;
}
.block_flex {
  position: relative;
}
.right_block {
  position: relative;
}
.buttons_holder_block {
  max-width: 350px;
  margin: auto;
  margin-top: 40px;
}
#account-main .row {
  width: 100%;
}

label[for="enterCardNumber"],
label[for="cardCvvCode"] {
  color: #3a3939;
  width: 100%;
  text-align: left;
  display: block;
}
label[for="cardExpireDate_input"] {
  color: #7b7979;
  width: 100%;
  text-align: left;
  display: block;
}


#enterCardNumber,
#cardCvvCode,
#cardExpireDate_from_input,
#cardExpireDate_to_input {
  background-color: #fff !important;
  box-shadow: 1px 1px 2px #5d5555;
  margin-top: 0;
  border: 1px solid #545454;
  color: #3a3939;
}
.second_card_holder > .col-lg-12 {
  position: relative;
  top: 100px;
  left: 265px;
  width: 65px;
}
.second_card_holder > .col-lg-12 input {
  max-width: 100%;
}
.first_card_holder .fields_holder {
  position: absolute;
  top: 60%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.first_card_holder .fields_holder div {
  text-align: left !important;
}
.left_block {
  margin-right: 25px;
}

@media(max-width: 720px){
  .close__button{
    display: none;
  }
}
@media(max-width: 579px){
  .right_block{
    height: 300px;
  }
  .first_card_holder {
    left: -100px;

}
.second_card_holder {
    top: 118px;
    left: -70px;
  }
}
@media(min-width: 579px) and (max-width: 1200px){
.first_card_holder {
    left: 0;

}
.second_card_holder {
    top: 18px;
    left: 90px;
  }
}
.text-selection-close{
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.relative{
  position: relative;
}
.unselectwrapper{
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
}
</style>
